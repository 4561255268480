import React, { useEffect, Fragment } from 'react';
import { parse } from 'query-string';
import { init } from 'onfido-sdk-ui';
import getOnfidoSteps from '../helpers/onfidoSteps';
import parseLocale from '../helpers/localeParser';
import axios from 'axios';
import addParamToUrl from '../helpers/addParamToUrl';
const { facialAuthAccountOptionRider, facialAuthAccountOptionSubRider } = require('../utils/shared');

function OnfidoStage(props) {
  const { applicantId, accountOption, clientId } = props.match.params;
  const { locale, browser_locale, accountName } = parse(props.location.search);

  async function stageComplete(error, applicantId, isFace, res) {
    let status = 'in_progress';
    if (error)
      status = 'error';
    let body = { status, accountOption };

    if (isFace) {
      let faceVariant = res?.face?.variant;
      body = { ...body, faceVariant };
    }

    let url = `/onfidov2/onfidostage/complete/${applicantId}`;
    url = addParamToUrl(url, 'clientId', clientId);
    await axios.post(url, body);
  }

  useEffect(() => {
    (() => {
      let onfido;
      const language = parseLocale(browser_locale) ?? parseLocale(locale) ?? 'en';
      const stepLang = (typeof (language) === "string") ? language : language.locale;
      let url = `/onfidov2/sdktoken/${applicantId}/${accountOption}/${clientId}`;
      let isFacialAuthFunnel = (accountOption === facialAuthAccountOptionRider || accountOption === facialAuthAccountOptionSubRider);
      axios.get(url)
        .then(async response => {
          const { sdkToken, document, isFace, variant } = response?.data;
          const steps = await getOnfidoSteps(document, isFace, stepLang, variant, accountName, isFacialAuthFunnel);
          if (sdkToken) {
            onfido = init({
              token: sdkToken,
              containerId: 'onfido-mount',
              language,
              steps,
              onComplete: res => stageComplete(null, applicantId, isFace, res),
              onError: error => stageComplete(error, applicantId)
            })
          }
        })
      return () => {
        onfido.tearDown();
      }
    })()
  }, [])

  return (
    <Fragment>
      <div id="onfido-mount"></div>
    </Fragment>
  );
}

export default OnfidoStage;
