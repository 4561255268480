import frPhrases from '../locales/fr.json';
import itPhrases from '../locales/it.json';
import nlPhrases from '../locales/nl.json';
import zhPhrases from '../locales/zh.json';

const onfidoLocales = process.env.REACT_APP_ONFIDO_LOCALES;
const customPhrases = {
    fr: frPhrases,
    it: itPhrases,
    nl: nlPhrases,
    zh: zhPhrases
};

const parseLocale = (language) => {
    if (!language)
        return null;
    else {
        let locale = ['fr-BE', 'nl-BE'].includes(language) ? language : language.split('-')[0];
        if (['fr', 'it', 'nl', 'zh'].includes(locale)) {
            return {
                locale: locale,
                phrases: customPhrases[locale]
            }
        } else {
            return onfidoLocales.split(',').includes(locale) ? locale : null;
        }
    }
}

export default parseLocale;