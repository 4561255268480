import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import OnfidoStage from './components/OnfidoStage';

const Routes = props => {
    return (
        <Router>
            <Route exact path='/client/onfidov2/:applicantId/:accountOption/:clientId' component={OnfidoStage}></Route>
        </Router>
    )
}

export default Routes;