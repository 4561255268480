import getLocaleSteps from './i18next';

const genericDocumentTypeConfig = {
    zusatzblatt: {
        id: 'zusatzblatt',
        title: 'Zusatzblatt',
        subTitle: 'Front and back',
        country: 'DEU',
        pages: 2,
    },
    fiktion: {
        id: 'fiktion',
        title: 'Fiktion',
        subTitle: 'Front and back',
        country: 'DEU',
        pages: 2,
    },
    visa: {
        id: 'visa',
        title: 'Visa',
        subTitle: 'Front and back',
        pages: 2,
    }
};

const document = {
    type: 'document',
    options: {
        genericDocumentTypes: [],
        documentTypes: {}
    }
};

const face = {
    type: 'face',
    options: {
        requestedVariant: 'video'
    }
};

const getOnfidoSteps = (documentOptions = [], isFace = false, locale, variant = 'video', accountName, isFacialAuthFunnel = false) => {
    return new Promise(async resolve => {
        let steps = await getLocaleSteps(locale, accountName, isFacialAuthFunnel);
        let checkPosition = 1;
        let requiredGenericDocumentTypes = [];

        if (documentOptions.length > 0) {
            documentOptions.forEach(option => {
              if(genericDocumentTypeConfig[option]) {
                requiredGenericDocumentTypes.push(genericDocumentTypeConfig[option]);
                document.options.documentTypes['generic_document'] = { id: option }
              } else {
                document.options.documentTypes[option] = true;
              }
            });

            document.options.genericDocumentTypes = requiredGenericDocumentTypes;
            steps.splice(checkPosition++, 0, document);
        }

        face.options.requestedVariant = variant;
        isFace && steps.splice(checkPosition, 0, face);

        resolve(steps);
    })
};

export default getOnfidoSteps;