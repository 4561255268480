import i18n from 'i18next';
import Backend from 'i18next-http-backend';

const getLocaleSteps = (locale, user, isFacialAuthFunnel = false) => {
    return new Promise(resolve => {
        let steps = [];
        i18n.use(Backend).init({
            lng: locale,
            fallbackLng: 'en',
            returnObjects: true,
            backend: {
                loadPath: isFacialAuthFunnel ? '/facial_auth/{{lng}}.json' : '/{{lng}}.json'
            }
        }).then(t => {
            steps.push(t('welcome', { user }));
            steps.push(t('complete'));
            resolve(steps);
        })
    })

}

export default getLocaleSteps;
